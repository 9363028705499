import React from "react";
import mail_logo from "../static/mail_logo.png";
import linkedin_logo from "../static/linkedIn.png";

class Contact extends React.Component {
  render() {
    return (
      <div
        id="contactPage"
        style={{ padding: "50px", background: "#000000DC" }}
      >
        <p
          className="h6 d-flex justify-content-center"
          style={{ color: "#FFB60A", fontSize: "11px" }}
        >
          CONTACT
        </p>

        <p
          className="h3 d-flex justify-content-center"
          style={{ color: "#fff" }}
        >
          Ways To Contact Me
        </p>

        <p
          className="d-flex justify-content-center"
          style={{ color: "#fff", fontSize: "13px" }}
        >
          Ready for an adventure? Whether it's backend, full stack, or front end
          development, find me on LinkedIn or shoot me a quirky email! 😄
        </p>

        <div
          className="row d-flex justify-content-center"
          style={{ paddingTop: "30px" }}
        >
          <div
            className="col-md-5"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginRight: "10px",
              padding: "15px",
            }}
          >
            <a
              className="d-flex justify-content-center"
              style={{ color: "#fff" }}
              href="mailto:asif.ibtehaz@tuni.fi"
            >
              <span style={{ paddingRight: "10px" }}>
                <img
                  src={mail_logo}
                  width="30px"
                  height="30px"
                  alt="email logo"
                ></img>
              </span>
              asif.ibtehaz@tuni.fi
            </a>
          </div>

          <div
            className="col-md-5"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginRight: "10px",
              padding: "15px",
            }}
          >
            <a
              className="d-flex justify-content-center"
              style={{ color: "#fff" }}
              href="https://www.linkedin.com/in/ibtehaz/"
              target="_blank"
              rel="noreferrer"
            >
              <span style={{ paddingRight: "10px" }}>
                <img
                  src={linkedin_logo}
                  width="30px"
                  height="30px"
                  alt="email logo"
                ></img>
              </span>
              Mohammad Asif Ibtehaz
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
