import React from "react";

class FooterMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: "",
      last_update: "",
    };
  }

  componentDidMount() {
    this.fetchGitTag();
  }

  fetchGitTag = () => {
    const localData = require("../../version.json");
    this.setState({
      tag: localData.tag,
      last_update: new Date(localData.date).toUTCString(),
    });
  };

  render() {
    return (
      <div
        className=""
        style={{
          background: "#000000FA",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <small
          className="d-flex justify-content-center"
          style={{ color: "#fff", fontSize: "9px" }}
        >
          Version: {this.state.tag}
        </small>

        <p
          className="d-flex justify-content-center"
          style={{ color: "#FFB60A", paddingTop: "5px", }}
        >
          with ❤️ from &nbsp; <a
              href="https://www.linkedin.com/in/ibtehaz/"
              style={{color: "#FFB60A"}}
            >
                Ibtehaz
            </a>
        </p>
      </div>
    );
  }
}

export default FooterMobile;
