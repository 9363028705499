import React from "react";
import backend_dev from "../static/backend_development.png";
import frontend_dev from "../static/frontend dev.png";
import server_mg from "../static/server management.png";

class Speciality extends React.Component {
  render() {
    return (
      <div style={{ padding: "50px", background: "#000000Bf" }}>
        <p
          className="h6 d-flex justify-content-center"
          style={{ color: "#FFB60A", fontSize: "11px" }}
        >
          SPECIALITY
        </p>

        <p
          className="h3 d-flex justify-content-center"
          style={{ color: "#fff" }}
        >
          What I Am Great At
        </p>

        <p
          className="d-flex justify-content-center"
          style={{ color: "#fff", fontSize: "13px" }}
        >
          Unleashing the Power of My Diverse Experience: Skills and Knowledge
          Gained Over the Years
        </p>

        {/* --------------------- */}
        <div
          className="row d-flex justify-content-center"
          style={{ paddingLeft: "50px", paddingRight: "50px" }}
        >
          <div
            className="col-md-3 d-flex justify-content-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginRight: "10px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            {/* col #1 */}
            <img
              src={backend_dev}
              width="50"
              height="50"
              alt="backend development logo"
            />
          </div>

          <div
            className="col-md-3 d-flex justify-content-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginLeft: "7px",
              marginRight: "7px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            {/* col #2 */}
            <img
              src={frontend_dev}
              width="50"
              height="50"
              alt="frontend development logo"
            />
          </div>

          <div
            className="col-md-3 d-flex justify-content-center"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginLeft: "7px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            {/* col #3 */}
            <img
              src={server_mg}
              width="50"
              height="50"
              alt="server management logo"
            />
          </div>
        </div>
        {/* --------------------- */}
        {/* --------------------- */}
        {/* description */}
        <div
          className="row d-flex justify-content-center"
          style={{
            paddingLeft: "50px",
            paddingRight: "50px",
            marginTop: "10px",
          }}
        >
          <div
            className="col-md-3 d-flex justify-content-left"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginRight: "10px",
              padding: "15px",
            }}
          >
            {/* col #1 */}
            <p className="" style={{ color: "#fff" }}>
              Backend Development
              <ul className="">
                <li>Years of Experience: 4+ years</li>
                <li>Language: Python</li>
                <li>Frameworks: Django, Flask, Tornado, Sanic </li>
                <li>Database: RDBMS (PostgreSQL), NoSQL (MongoDB) </li>
                <li>In Memory Database: Redis</li>
              </ul>
            </p>
          </div>

          <div
            className="col-md-3 d-flex justify-content-left"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginLeft: "7px",
              marginRight: "7px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            {/* col #2 */}
            <p className="" style={{ color: "#fff" }}>
              FrontEnd Development
              <ul className="">
                <li>Years of Experience: 1+ years</li>
                <li>Language: JavaScript</li>
                <li>Frameworks: React </li>
                <li>CSS Frameworks: Bootstrap </li>
              </ul>
            </p>
          </div>

          <div
            className="col-md-3 d-flex justify-content-left"
            style={{
              borderRadius: "10px",
              backgroundColor: "#000000DC",
              marginLeft: "7px",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            {/* col #3 */}
            <p className="" style={{ color: "#fff" }}>
              Server Management
              <ul className="">
                <li>Years of Experience: 4+ years</li>
                <li>OS: Ubuntu, Debian</li>
                <li>Speciality: CronJobs, shell scripts, server management and resource monitoring</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Speciality;
