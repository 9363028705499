import React from "react";
import logo from "../static/ibtehaz_sign_white_bg_big_1.png";

class Navigation extends React.Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light bg-dark"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <a className="navbar-brand" href="#homePage" style={{ color: "#fff" }}>
          <img
            src={logo}
            width="100"
            height="50"
            class="d-inline-block align-top"
            alt=""
          />
        </a>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <a
              className="nav-item nav-link active"
              href="#homePage"
              style={{ color: "#fff" }}
            >
              Home
            </a>
            <a
              className="nav-item nav-link"
              href="https://www.linkedin.com/in/ibtehaz/"
              style={{ color: "#fff" }}
            >
              Portfolio
            </a>
            <a
              className="nav-item nav-link"
              href="#contactPage"
              style={{ color: "#fff" }}
            >
              Contact
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
