import React from "react";

class UnderDevelopment extends React.Component {
  render() {
    return (
      <div
        className="justify-content-center"
        style={{
          height: "100vh",
          backgroundColor: "#000000DC",
          padding: "20px",
        }}
      >
        <div className="row">
          <p className="h1 d-flex justify-content-center" style={{ color: "#FFB60A" }}>
            Sorry!
          </p>
        </div>

        <div  className="row">
          <p className="d-flex justify-content-center" style={{ color: "#fff" }}>
            This page is currently not optimized for mobile devices. Please
            access it from your computer or tablet for the best viewing
            experience.
          </p>
        </div>
      </div>
    );
  }
}

export default UnderDevelopment;
