import React from "react";
import my_img from "../../static/Ibtehaz.png";
import "../../css/App.css";

class IntroMobile extends React.Component {
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          padding: "20px",
          background: "#fef6e7",
        }}
      >
        {/* header start */}
        <div className="row d-flex justify-content-center">
          {/* image */}
          <div className="circle-frame">
            <div className="circle-content">
              <img
                src={my_img}
                alt="Ibtehaz - Potrait"
                width="150"
                height="150"
              />
            </div>
          </div>
          {/* image ends */}
          <p className="h1" style={{ color: "#9D44B5" }}>
            Hello!
          </p>
          <p className="h6">
            I am Ibtehaz, &nbsp;
            <span style={{ color: "#9D44B5" }}>A Full Stack Developer.</span>
          </p>
        </div>
        {/* header ends */}
        <hr></hr>
        {/* content starts */}
        <p className="content">
          I have 4 years of experience as a backend developer in Python. I have
          worked with <b>Flask, Django, Tornado and Sanic.</b>
          <br />I have taught myself React last year and worked on some pretty
          cool projects. Some example would be
          <ul>
            <li>
              <a
                href="https://github.com/shepherd-06/Solita-FrontEnd"
                target="_blank"
                rel="noreferrer"
              >
                City Bike App (Pre-Assignment for Solita Dev Academy)
              </a>
            </li>
            <li>
              <a
                href="https://github.com/shepherd-06/Wolt-Summer-Intern-2023"
                target="_blank"
                rel="noreferrer"
              >
                Delivery Calculator App (Pre-Assignment for Wolt)
              </a>
            </li>
          </ul>
          I am also familiar with RDBS (<b>PostgreSQL</b>), NoSQL (
          <b>MongoDB</b>), In-Memory Database (<b>Redis</b>).
        </p>
        {/* content ends */}
        <hr></hr>
        {/* contact option starts */}
        <p className="content">
          You can checkout my other projects from my{" "}
          <a
            href="https://github.com/shepherd-06"
            target="_blank"
            rel="noreferrer"
          >
            <b>Github.</b>
          </a>{" "}
          <br></br>
          Easiest way to contact me is through{" "}
          <a
            href="https://www.linkedin.com/in/ibtehaz/"
            target="_blank"
            rel="noreferrer"
          >
            <span style={{ fontSize: "14px" }}>
              <b>Linkedin</b>
            </span>
          </a>{" "}
          , or send me an email at{" "}
          <a href="mailto:asif.ibtehaz@tuni.fi">
            {" "}
            <span style={{ fontSize: "14px" }}>
              <b>asif.ibtehaz@tuni.fi</b>
            </span>
          </a>
          . <br />
          Sometimes I write blogs in Medium. They are mostly non-technical!{" "}
          <a
            href="https://shepherd06.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            Check them out here.
          </a>
        </p>
        {/* contact option ends */}
        <hr></hr>
        <p className="d-flex justify-content-center">
          I am looking forward to join in cool adventure with you! 😎
        </p>
      </div>
    );
  }
}

export default IntroMobile;
