import React from "react";
import "./css/App.css";
import Navigation from "./component/navigation";
import Intro from "./component/Intro";
import Speciality from "./component/Speciality";
import Contact from "./component/Contact";
import Footer from "./component/Footer";

// *--------------*
// mobile view
// *--------------*
import UnderDevelopment from "./mobileview/UnderDev";
import MobileNavigation from "./mobileview/component/NavMobile";
import FooterMobile from "./mobileview/component/FooterMobile";
import IntroMobile from "./mobileview/component/IntroMobile";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowSize: window.innerWidth });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.windowSize !== this.state.windowSize) {
      this.setState({ windowSize: window.innerWidth });
    }
  }

  render() {
    // use state here.
    // use component update function as well.
    const isMobileView = this.state.windowSize <= 1100;

    if (isMobileView) {
      return (
        <div>
          <MobileNavigation />
          <IntroMobile />
          <FooterMobile />
        </div>
      );
    }

    return (
      <div>
        <Navigation />

        <Intro />
        <Speciality />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default App;
