import React from "react";
import my_img from "../static/Ibtehaz.png";
import "../css/App.css";

class Intro extends React.Component {
  render() {
    return (
      <div
        id="homePage"
        className="row"
        style={{ backgroundColor: "#000000DC" }}
      >
        <div className="col-lg-3" style={{ paddingLeft: "50px" }}>
          {/* left */}
          <p className="h1" style={{ color: "#fff", paddingTop: "70px" }}>
            Ibtehaz <span style={{ color: "#FFB60A" }}>.</span>
          </p>
          <p className="h3" style={{ color: "#FFB60A" }}>
            ____
          </p>
          <p className="h6" style={{ color: "#fff" }}>
            LinkedIn:
            <a
              href="https://www.linkedin.com/in/ibtehaz/"
              style={{ color: "#FFB60A", paddingLeft: "10px" }}
              target="_blank"
              rel="noreferrer"
            >
              https://www.linkedin.com/in/ibtehaz/
            </a>
          </p>
          <p className="h6" style={{ color: "#fff" }}>
            GitHub:
            <a
              href="https://github.com/shepherd-06"
              style={{ color: "#FFB60A", paddingLeft: "10px" }}
              target="_blank"
              rel="noreferrer"
            >
              https://github.com/shepherd-06
            </a>
          </p>
        </div>
        {/* ----------------------------------------------------- */}

        <div className="col-lg-5 d-flex justify-content-center">
          {/* center */}
          <div className="circle-frame-web">
            <div className="circle-content">
              <img
                src={my_img}
                alt="Ibtehaz - Potrait"
                width="300"
                height="300"
              />
            </div>
          </div>
          {/* <img src={my_img} alt="Ibtehaz - Potrait" width="350" height="350" /> */}
        </div>

        {/* ----------------------------------------------------- */}
        <div className="col-lg-4" style={{ paddingRight: "50px" }}>
          <p style={{ color: "#FFB60A", paddingTop: "70px" }}> Introduction </p>
          <p className="h3" style={{ color: "#fff" }}>
            Full Stack Developer
          </p>
          <p style={{ color: "#fff" }}>
            A versatile individual with a wide range of work experience, going
            from coding wizard to social media strategy and video editing whiz.
          </p>
          <a
            href="https://www.linkedin.com/in/ibtehaz/"
            style={{ color: "#FFB60A" }}
            target="_blank"
            rel="noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }
}

export default Intro;
