import React from "react";
import medium_logo from "../static/medium.png";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: "",
      last_update: "",
    };
  }

  componentDidMount() {
    this.fetchGitTag();
  }

  fetchGitTag = () => {
    const localData = require("../version.json");
    this.setState({
      tag: localData.tag,
      last_update: new Date(localData.date).toLocaleString("en-GB"),
    });
  };

  render() {
    return (
      <div
        className="row d-flex justify-content-center"
        style={{ background: "#000000FA", paddingTop: "5px" }}
      >
        <div className="col-md-3">
          <small style={{ color: "#fff", fontSize: "9px" }}>
            Version: {this.state.tag}, Deployed at {this.state.last_update}
          </small>
        </div>
        <div
          className="col-md-4 d-flex justify-content-center"
          style={{ color: "#FFB60A" }}
        >
          <p style={{ color: "#FFB60A", paddingTop: "5px" }}>
            with ❤️ from &nbsp;
            <a
              href="https://www.linkedin.com/in/ibtehaz/"
              style={{ color: "#FFB60A" }}
              target="_blank" rel="noreferrer"
            >
              Ibtehaz
            </a>
          </p>
        </div>

        <div className="col-md-3">
          <small style={{ color: "#fff", fontSize: "9px" }}>
            <span style={{ paddingRight: "10px" }}>
              <img
                src={medium_logo}
                width="15px"
                height="15px"
                alt="email logo"
              ></img>
            </span>
            <a
              style={{ color: "#fff" }}
              href="https://shepherd06.medium.com/"
              target="_blank" rel="noreferrer"
            >
              (Sometimes) I write.
            </a>
          </small>
        </div>
      </div>
    );
  }
}

export default Footer;
