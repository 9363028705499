import React from "react";
import logo from "../../static/ibtehaz_sign_white_bg_big_1.png";

class MobileNavigation extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark"
      
      style={{paddingLeft: "10px", paddingRight: "10px"}}>
        <a className="navbar-brand" href="https://ibtehaz.xyz" style={{ color: "#fff" }}>
          <img
            src={logo}
            width="100"
            height="50"
            class="d-inline-block align-top"
            alt=""
          />
        </a>
      </nav>
    );
  }
}

export default MobileNavigation;
